import { useUserSession } from '@/components/shared/UserSessionContext';
import { UserRole } from 'const-utils';
import { useMemo } from 'react';

export const techSupportRoles = [UserRole.TechSupport];
export const engagementRoles = [
  UserRole.PatientEngagementAssociate1,
  UserRole.PatientEngagementAssociate2,
  UserRole.ProviderEngagementSpecialist,
  UserRole.OutreachSupervisor,
  UserRole.Outreach,
];
export const engagementManagerRoles = [UserRole.OutreachSupervisor];
export const bhTOCManagerRoles = [UserRole.BHClinicalDirector, UserRole.TransitionOfCareCoordinator];

// useHasRole checks if the current user has one of the target roles
export const useHasRole = (targetRoles: UserRole[]): boolean => {
  const { roles } = useUserSession();
  return useMemo(() => roles.some((r) => targetRoles.includes(r.code)), [roles, targetRoles]);
};
