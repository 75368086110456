import { ProfileResource } from '@medplum/core';
import { Reference, Task } from '@medplum/fhirtypes';
import { System, TaskType } from 'const-utils';
import { PractitionerInfoFragment } from 'medplum-gql';
import { isNil } from 'lodash';
import { ResourceBuilderParams } from './resource';
import { isAfter } from 'date-fns';
import { Owner } from './types/task';

export const countUnassignedTasks = (taskOwners: (PractitionerInfoFragment | Owner | undefined | null)[]): number => {
  return taskOwners.filter((o) => isNil(o)).length;
};

export const countTasksAssignedToProfile = (ownerIds: string[], profile: ProfileResource): number => {
  return ownerIds.reduce((count, ownerId) => count + (ownerId === profile?.id ? 1 : 0), 0);
};

export const hasOverdueAssignedTasks = (dueDates: string[]): boolean => {
  return dueDates.some((d) => isAfter(new Date(), d));
};

type ManagedFields = {
  for: NonNullable<Task['for']>;
  type: TaskType;
  intent?: Task['intent'];
};

type CreateTaskParams = ResourceBuilderParams<Task, ManagedFields>;

/**
 *
 * @param args - the details of the task to create
 * @param args.for - the resource the task is for
 * @param args.type - the type of task which will be used to tag the task
 * @param args.intent - the intent of the task, defaults to 'order'
 * @returns a task object that can be used to create a task resource
 */
export const buildTask = (args: CreateTaskParams): Task => {
  const { for: forResource, focus, intent, type, ...rest } = args;

  const now = new Date().toISOString();
  return {
    resourceType: 'Task',
    authoredOn: now,
    ...rest,
    for: forResource,
    intent: intent || 'order',
    focus,
    lastModified: now,
    executionPeriod: { start: now },
    meta: {
      ...(args.meta ?? {}),
      tag: [
        { code: type, display: type, system: System.TaskType },
        ...(args.meta?.tag ?? []).filter((t) => t.system !== System.TaskType),
      ],
    },
  };
};
type OutreachManagedFields = {
  forPatientId: string;
  assignToPractitionerId: string;
  requestedByPractitionerId: string;
  dueDate: string;
  taskType: { code: string; display: string };
  businessStatus: string;
  notes?: string;
  priority?: 'routine' | 'urgent' | 'asap' | 'stat' | undefined;
  id?: string;
  createdDate?: string;
  bulkTask?: boolean;
  basedOn?: Reference[];
  communicationRequestId?: string;
};
/**
 *
 * @param args - the details of the outreach task to create
 * @param args.forPatientId - the patient the task is for
 * @param args.assignToPractitionerId - the practitioner the task is assigned to
 * @param args.requestedByPractitionerId - the practitioner who requested the task
 * @param args.dueDate - the date the task is due
 * @param args.taskType - the type of task which will be used to tag the task
 * @param args.businessStatus - the status of the task
 * @param args.notes - any notes to add to the task
 * @param args.priority - the priority of the task
 * @param args.id - the id of the task to update - if not provided it will create a new task
 * @param args.createdDate - the date the task was originally created
 * @param args.bulkTask - whether the task is part of a bulk task creation
 *
 * @returns a task object that can be used to create an outreach task resource
 */
export const buildOutreachTask = (args: OutreachManagedFields): Task => {
  const {
    forPatientId,
    assignToPractitionerId,
    requestedByPractitionerId,
    dueDate,
    taskType,
    businessStatus,
    notes,
    priority,
    id,
    createdDate,
    bulkTask = false,
    basedOn,
    communicationRequestId,
  } = args;

  const noteToAdd = notes
    ? {
        note: [
          {
            text: notes,
          },
        ],
      }
    : undefined;

  const bulkTaskTag = bulkTask
    ? [
        {
          system: System.BulkTask,
          code: 'bulkTask',
          display: 'Created as part of a bulk task creation',
        },
      ]
    : [];

  return {
    id: id,
    resourceType: 'Task',
    status: 'ready',
    priority: priority,
    intent: 'order',
    for: {
      reference: `Patient/${forPatientId}`,
    },
    owner: {
      reference: `Practitioner/${assignToPractitionerId}`,
    },
    code: {
      coding: [
        {
          system: System.TaskType,
          ...taskType,
        },
      ],
    },
    meta: {
      tag: [
        {
          system: System.TaskCategory,
          code: 'outreach',
        },
        {
          system: System.TaskType,
          ...taskType,
        },
        ...bulkTaskTag,
      ],
    },
    requester: {
      reference: `Practitioner/${requestedByPractitionerId}`,
    },
    businessStatus: {
      coding: [
        {
          system: System.TaskPriority,
          code: businessStatus,
        },
      ],
    },
    authoredOn: createdDate,
    lastModified: id ? new Date().toISOString() : createdDate,
    restriction: {
      period: {
        end: dueDate,
      },
    },
    executionPeriod: {
      start: createdDate,
    },
    ...noteToAdd,
    ...(communicationRequestId || basedOn
      ? {
          basedOn: [
            ...(communicationRequestId ? [{ reference: `CommunicationRequest/${communicationRequestId}` }] : []),
            ...(basedOn || []),
          ],
        }
      : {}),
  };
};

/**
 * Determines the priority of a task based on the number of days since a reference date.
 *
 * @param dateDifference - The number of days between the current date and a reference date (e.g., encounter end date)
 * @returns The priority of the task: 'urgent', 'asap', or 'routine'
 *
 * Priority levels:
 * - 'urgent': 0-7 days (high)
 * - 'asap': 8-30 days (medium)
 * - 'routine': 31+ days (low)
 */
export const getTaskPriority = (dateDifference: number): string => {
  if (dateDifference >= 0 && dateDifference <= 7) {
    return 'high';
  } else if (dateDifference > 7 && dateDifference <= 30) {
    return 'medium';
  } else {
    return 'low';
  }
};

export const generateTaskFilter = (
  status: 'incomplete' | 'complete',
  patientRef: string,
  marketsString: string,
  ownerFilter: string,
  dueDateStart?: string,
  dueDateEnd?: string,
): string => {
  const statusFilter =
    status === 'incomplete'
      ? 'status ne completed,rejected,accepted,cancelled'
      : 'status eq completed,rejected,accepted,cancelled';

  const patientFilter = patientRef ? ` and subject re ${patientRef}` : '';
  const dueDateFilter =
    dueDateStart && dueDateEnd ? ` and due-date ge ${dueDateStart} and due-date le ${dueDateEnd}` : '';

  return `${statusFilter} and patient.organization re ${marketsString}${patientFilter}${ownerFilter}${dueDateFilter}`;
};
